import { defineAsyncComponent } from "vue";
export default [
  {
    path: "/finance/payment",
    name: "finance-payment",
    title: "Оплаты Ultimatum Store",
    component: () => import("../template/pages/templateCollection.vue"),
    meta: {
      infocollection: {
        name: "Платежи",
        item: defineAsyncComponent(() =>
          import("@/components/service/payment/item.vue")
        ),
      },
      collection: "payment",
      populate: [],
      filter: {
        object: ["id"],
        text: "Найти по номеру телефона",
        placeholder: "введите номер телефона клиента",
        menufilter: [
          { name: "Исходящие", key: "status", action: true },
          { name: "Входящие", key: "status", action: false },
        ],
      },
    },
  },
  {
    path: "/finance/check",
    name: "finance-check",
    title: "Чеки Ultimatum Store",
    component: () => import("../template/pages/templateCollection.vue"),
    meta: {
      infocollection: {
        name: "Чеки",
        item: defineAsyncComponent(() =>
          import("@/components/service/check/item.vue")
        ),
      },
      collection: "check",
      populate: [],
      filter: {
        object: ["ordernumber"],
        text: "Найти по номеру заказа",
        placeholder: "введите номер заказа",
         menufilter: [
          { name: "Исходящие", key: "type", action: 'out' },
          { name: "Входящие", key: "type", action: 'in' },
        ],
      },
    },
  },
  {
    path: "/finance/avansandbonus",
    name: "finance-avansandbonus",
    title: "Начисления Ultimatum Store",
    component: () => import("../template/pages/templateCollection.vue"),
    meta: {
      infocollection: {
        name: "Начисления",
        item: defineAsyncComponent(() =>
          import("@/components/service/avansandbonus/item.vue")
        ),
      },
      collection: "avansandbonus",
      populate: [{path:'client',select:['firstname','lastname']}],
      filter: {
        object: ["id"],
        text: "Найти по номеру телефона",
        placeholder: "введите номер телефона клиента",
        menufilter: [
          { name: "Аванс", key: "service", action: 'return-advance' },
          { name:"Ультиматум селект",key: "service", action: 'ad_deposit'},
          { name: "Бонус", key: "service", action: 'bonus'},
        ],
      },
      dopolnenie:[
        defineAsyncComponent(() => import("@/components/service/avansandbonus/addavansandbonus.vue")),
      ]
    },
  },
  {
    path: "/finance/kassa",
    name: "finance-kassa",
    title: "Касса Ultimatum Store",
    component: () => import("../components/service/check/kassa.vue"),
    meta: {
      collection: "check",
    }
  },
];
